'use client';
import { useState, useEffect } from 'react';
import { useTheme } from 'next-themes';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import classNames from 'classnames';

type DarkModeProps = {
  className?: string;
  hasDarkThemeCookie?: boolean;
};

export const DarkModeIcon = ({ className, hasDarkThemeCookie }: DarkModeProps) => {
  const [mounted, setMounted] = useState(false);
  const { theme, setTheme } = useTheme();

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (theme === 'system') {
      return setTheme(window.matchMedia('(prefers-color-scheme: dark)')?.matches ? 'dark' : 'light');
    }
    document.cookie = `DARK_MODE_COOKIE=${theme}; path=/; expires=Fri, 31 Dec 2100 23:59:59 GMT`;
  }, [setTheme, theme]);

  return (
    <div
      className={classNames(
        'cursor-pointer rounded-lg px-3 py-2 opacity-50 transition-opacity hover:bg-white/10 hover:opacity-100',
        className,
      )}
      onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}
    >
      {mounted ? (
        <DarkModeSwitch
          checked={theme === 'dark'}
          onChange={() => undefined}
          sunColor="#ffffff"
          moonColor="#ffffff"
          size="20px"
        />
      ) : (
        <DarkModeSwitch
          checked={Boolean(hasDarkThemeCookie)}
          onChange={() => undefined}
          sunColor="#ffffff"
          moonColor="#ffffff"
          size="20px"
        />
      )}
    </div>
  );
};

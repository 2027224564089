/* eslint-disable @next/next/no-img-element */
'use client';

import { useEffect, useState } from 'react';
import { Dialog } from '@headlessui/react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import { usePathname } from 'next/navigation';
import Image from 'next/image';
import classNames from 'classnames';

import { Meta, Navigation as SanityNavigation } from '@/types/sanity.types';
import { useScrollPosition } from '@/hooks/useScrollPosition';
import { LocaleKey, defaultLocaleKey } from '@/constants/locales';
import { LanguageSelector } from './LanguageSelector';
import { DarkModeIcon } from './DarkModeIcon';

interface Navigation extends SanityNavigation {
  current: boolean;
}

type NavigationComponentProps = {
  navigation: SanityNavigation[];
  meta: Meta;
  localeKey: LocaleKey;
  hasDarkThemeCookie: boolean;
};

const getNavigation = (localeKey: LocaleKey, nav: SanityNavigation[], currentPathName: string | null): Navigation[] =>
  nav
    .sort((a, b) => Number(a?.page_index) - Number(b?.page_index))
    .map((item) => ({ ...item, current: getNavigationUrl(localeKey, item.page_url) === currentPathName }));

const getNavigationUrl = (localeKey: LocaleKey, path: string = ''): string => {
  if (path?.startsWith('http')) {
    return path;
  }
  if (localeKey !== defaultLocaleKey) {
    return `/${localeKey}/${path}`;
  }
  return `/${path}`;
};

export const Navigation = (props: NavigationComponentProps) => {
  const { meta, localeKey, hasDarkThemeCookie } = props;
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const pathname = usePathname();

  // TODO: move this to SANITY when finished
  const contact: Navigation = {
    page_index: 4,
    page_url: 'contact',
    page_title: 'Contact',
    current: getNavigationUrl(localeKey, 'contact') === pathname,
    transparent_bg_when_scrolled_up: false,
  } as Navigation;

  const navigation = [...getNavigation(localeKey, props.navigation, pathname), contact];
  const isHomePage = pathname === '/' || pathname === `/${localeKey}`;
  const bgScrolledUpTransparency =
    isHomePage ||
    Boolean(
      navigation.find(({ current, transparent_bg_when_scrolled_up }) => current && transparent_bg_when_scrolled_up),
    );
  const [isScrolledUp, setIsScrolledUp] = useState(
    !bgScrolledUpTransparency ? false : typeof window === 'undefined' || !window.scrollY,
  );
  useScrollPosition(({ isScrolledUp }) => setIsScrolledUp(!bgScrolledUpTransparency ? false : isScrolledUp), []);

  useEffect(() => {
    setTimeout(() => setHasLoaded(true), 10);
  }, []);

  return (
    <header
      className={classNames('inset-x-0 top-0 z-50 px-5 text-white duration-300 sm:px-10', {
        absolute: isScrolledUp,
        fixed: !isScrolledUp,
        'bg-background-800/80': !isScrolledUp,
        'dark:bg-background-900/80': !isScrolledUp,
        'backdrop-blur-lg': !isScrolledUp,
        'shadow-xl': !isScrolledUp,
        'transition-all': hasLoaded,
      })}
      suppressHydrationWarning
    >
      <nav className="container mx-auto flex items-center justify-between py-2 sm:p-6" aria-label="Global">
        <div className="flex lg:flex-1">
          <div className="flex items-center gap-4">
            <a href={getNavigationUrl(localeKey)} title="Home">
              <Image
                src="/k29-logo.svg"
                alt="Koinonia 29 Logo"
                width="25"
                height="19"
                className={classNames('block w-auto object-contain', {
                  'h-16 sm:h-20': isScrolledUp,
                  'h-10 sm:h-14': !isScrolledUp,
                  'transition-all': hasLoaded,
                })}
                suppressHydrationWarning
              />
            </a>
            <div className="flex flex-col">
              <h2 className="whitespace-nowrap text-lg font-bold uppercase tracking-wide">{meta.church_name}</h2>
              <p className="text-balance line-clamp-2 max-w-[200px] pr-2 text-xs sm:max-w-[250px] sm:text-sm">
                {meta.church_description}
              </p>
            </div>
          </div>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            onClick={() => setMobileMenuOpen(true)}
            title="Navigation"
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-8 w-8 text-white" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-6 xl:gap-x-12">
          {navigation.map((item) => (
            <a
              key={item.page_title}
              href={getNavigationUrl(localeKey, item.page_url)}
              target={item.new_tab ? '_blank' : '_self'}
              className={classNames(
                'rounded-lg px-3 py-2 text-sm font-semibold leading-6 text-white underline-offset-8 transition-all hover:bg-white/10',
                {
                  underline: item.current,
                },
              )}
            >
              {item.page_title}
            </a>
          ))}
          <div className="flex">
            <DarkModeIcon hasDarkThemeCookie={hasDarkThemeCookie} />
            <LanguageSelector localeKey={localeKey} />
          </div>
        </div>
        {/* <div className="hidden lg:flex lg:flex-1 lg:justify-end">
            <a href="#" className="text-sm font-semibold leading-6 text-white">
              Log in <span aria-hidden="true">&rarr;</span>
            </a>
          </div> */}
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-gray-600 px-6 py-6 text-gray-200 transition-colors dark:bg-gray-900 dark:text-gray-400 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <a href={getNavigationUrl(localeKey)} className="-m-1.5 p-1.5">
              <span className="sr-only">{meta.church_name}</span>
              <img src="/k29-logo.svg" alt="Koinonia 20 Logo" className="block h-10 w-auto transition-all" />
            </a>
            <button type="button" className="-m-2.5 rounded-md p-2.5" onClick={() => setMobileMenuOpen(false)}>
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-8 w-8" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {navigation.map((item) => (
                  <a
                    key={item.page_title}
                    href={getNavigationUrl(localeKey, item.page_url)}
                    target={item.new_tab ? '_blank' : '_self'}
                    className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-800"
                  >
                    {item.page_title}
                  </a>
                ))}
              </div>

              <div className="flex py-6">
                <DarkModeIcon className="-ml-4" hasDarkThemeCookie={hasDarkThemeCookie} />
                <LanguageSelector localeKey={localeKey} alignDropDown="left" />
              </div>

              {/* <div className="py-6">
                  <a className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-white hover:bg-gray-800">
                    Log in
                  </a>
                </div> */}
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
};
